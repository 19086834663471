import { apiSlice } from "../../api/apiSlice"

export const changeAgentsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getChangeAgents: builder.query({
            query: (id) => {
                if (id) return `report/LHIPD/change-agents/${id}`;
                throw new Error("ID is undefined");
            },
            keepUnusedDataFor: 600,
            transformResponse: (res) => res.sort((a, b) => b.impact - a.impact),
        })
    })
})

export const {
    useGetChangeAgentsQuery
} = changeAgentsApiSlice 