import { ViolinChart } from "./ViolinChart";
import { InfoCircle } from "react-bootstrap-icons";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ReactMarkdown from "react-markdown";
import ReactDOM from "react-dom/client";
import { useSelector } from "react-redux";
import { selectActiveRole } from "../../features/auth/authSlice";
import { useMemo } from "react";

const ViolinLayout = ({
  dataset,
  userValue,
  benchmarkValue,
  title,
  info_button = false,
  scale_info,
  filtered = false,
  selected_filter,
}) => {
  
  let targetLength = 0;
  let targetLabel = "";
  let margins = [10, 100, 10, 10]; // [t, l, b, r]

  const activeRole = useSelector(selectActiveRole);

  //Determine which violin chart components is to be displayed
  const { showDataPoints, showUserValue, showBenchmarkValue, showSampleMean } =
    useMemo(() => {
      if (activeRole === "user_admin") {
        return {
          showDataPoints: true,
          showUserValue: false,
          showBenchmarkValue: true,
          showSampleMean: true,
        };
      }
      return {
        showDataPoints: false,
        showUserValue: true,
        showBenchmarkValue: false,
        showSampleMean: false,
      };
    }, [activeRole]);

  // auto adjust left margin if text gets larger than 100 px
  if (filtered) {
    dataset.forEach((item) => {
      const label = item[selected_filter];

      // Skip items where the selected_filter is missing or undefined
      if (!label) {
        return;
      }

      const labelLength = label.length;

      if (labelLength > targetLength) {
        targetLength = labelLength;
        targetLabel = label;
      }
    });

    if (targetLabel && targetLength * 7 > margins[1]) {
      const numCapitals = (targetLabel.match(/[A-Z]/g) || []).length;
      const lowerCaseLength = targetLength - numCapitals;
      const capitalMultiplier = 9;
      const lowerCaseMultiplier = 7;

      margins[1] =
        lowerCaseLength * lowerCaseMultiplier + numCapitals * capitalMultiplier;
    }
  }

  const MySwal = withReactContent(
    Swal.mixin({
      customClass: {
        confirmButton: "btn btn-primary ",
      },
      buttonsStyling: false,
    })
  );

  const fireModal = (input, error = false) => {
    const container = document.createElement("div");

    // Render the ReactMarkdown component into the container
    const root = ReactDOM.createRoot(container);
    root.render(<ReactMarkdown>{input["description"]}</ReactMarkdown>);

    MySwal.fire({
      title: <h4>{input["title"]}</h4>,
      html: container,
      showCancelButton: false,
      confirmButtonText: "close",
    });
  };

  const handleInfoButton = (e) => {
    e.preventDefault();
    fireModal({ title: title, description: scale_info });
  };

  return (
    <>
      <div className="col-lg-6 mt-3">
        <div className="box">
          <div className="d-flex box-title justify-content-between">
            <div className="box-title">{title}</div>
            {info_button && (
              <div
                role="button"
                onClick={(e) => {
                  handleInfoButton(e);
                }}
                className="info-button"
              >
                <InfoCircle color="#0B3954" size={24} />
              </div>
            )}
          </div>

          <ViolinChart
            dataset={dataset}
            filtered={filtered}
            selected_filter={selected_filter}
            userValue={showUserValue && userValue}
            benchmarkValue={showBenchmarkValue && !filtered && benchmarkValue}
            showDataPoints={showDataPoints && !filtered}
            showSampleMean={showSampleMean}
            margins={margins}
          />
        </div>
      </div>
    </>
  );
};

export default ViolinLayout;
