import { Outlet, useLocation, Navigate } from "react-router-dom";
import { useEffect, useRef, useState, useMemo } from "react";
import { useRefreshMutation } from "./authApiSlice";
import usePersist from "../../hooks/usePersist";
import { useSelector } from "react-redux";
import { selectCurrentToken, selectCurrentLoggedOut } from "./authSlice";

const PersistLogin = () => {
  const [persist] = usePersist();
  const location = useLocation();
  const token = useSelector(selectCurrentToken);
  const loggedOut = useSelector(selectCurrentLoggedOut);
  const effectRan = useRef(false);

  const [trueSuccess, setTrueSuccess] = useState(false);

  const [refresh, { isUninitialized, isLoading, isSuccess, isError, error }] =
    useRefreshMutation();

  useEffect(() => {
    if (effectRan.current === true || process.env.NODE_ENV !== "development") {
      // React 18 Strict Mode

      const verifyRefreshToken = async () => {
        try {
          //Requesting a new access tokem
          await refresh();
          setTrueSuccess(true);
        } catch (err) {
          console.error(err);
        }
      };

      if (!token && persist) verifyRefreshToken();
    }

    return () => (effectRan.current = true);

    // eslint-disable-next-line
  }, []);

  const content = useMemo(() => {
    if (!persist || loggedOut) return <Outlet />;
    if (isLoading) return <p>Loading...</p>;
    if (isError) return <Navigate to="/login" state={{ from: location }} replace />;
    if ((isSuccess && trueSuccess) || (token && isUninitialized)) return <Outlet />;
  }, [persist, loggedOut, isLoading, isError, isSuccess, trueSuccess, token, isUninitialized, location]);

  return content;
};
export default PersistLogin;
