import * as Plot from "@observablehq/plot";
import * as d3 from "d3";
import { useEffect, useRef, useState } from "react";
import {
  kde,
  epanechnikov,
  flattenNestedMap,
  hci_reducer,
  lci_reducer,
} from "../functions/functions";

export const ViolinChart = ({
  dataset,
  showDataPoints, // depends on some conditions
  filtered,
  selected_filter,
  margins,
  userValue, // becomes markerValue
  benchmarkValue,
  showSampleMean,
}) => {
  const containerRef = useRef();

  // kde estimates to draw the overall violin
  const scale_kde_points = () =>
    kde(
      epanechnikov(0.6),
      d3.range(0, 7, 0.1),
      dataset.map((d) => d.value),
      true
    );

  // kde estimates to draw the filtered violins. exact parameters might need further tweaking
  const filtered_kde_points = () =>
    flattenNestedMap(
      d3.rollup(
        dataset,
        (G) =>
          kde(
            epanechnikov(0.6),
            d3.range(1, 7, 0.1),
            G.map((d) => d.value),
            true
          ),
        (d) => d[selected_filter]
      )
    );

  const getLabelOriantation = (value) => {

    if (value < 2) return "top-left"
    if (value >= 2 && value <= 5) return "top"

    return "top-right"
  }

  useEffect(() => {
    if (dataset === undefined) return;
    const meanValue = d3.mean(dataset.map((d) => d.value));

    const plot = Plot.plot({
      height: filtered ? 500 : 300, //this could become proportional to N of filter levels
      marginLeft: margins[1],
      x: { domain: [1, 6] },
      fy: {
        // domain: ["Meno di un anno", "1-3 anni", "4-5 anni", "6-10 anni", "Più di 10 anni"],
        axis: "left",
        padding: 0.2,
      },
      y: { axis: null },
      marks: [
        // this adds the vertical line, benchmark value for the overall, overall value in the filtered version
        Plot.ruleX(
          { length: 1 },
          {
            x: benchmarkValue ? benchmarkValue : meanValue,
            stroke: filtered ? "lightblue" : "lightgray",
            strokeWidth: 3,
            tip: { anchor: getLabelOriantation(benchmarkValue ? benchmarkValue : meanValue) , maxRadius: 6 },
            title: benchmarkValue
              ? () => `Benchmark: ${benchmarkValue}`
              : () => `Overall: ${d3.format(".2f")(meanValue)}`,
          }
        ),
        // this adds the area to draw the violin actual Violin surv
        Plot.areaY(filtered ? filtered_kde_points() : scale_kde_points(), {
          x: "x",
          y1: "y1",
          y2: "y2",
          fy: filtered ? "outerKey" : null,
          fill: "lightblue",
          fillOpacity: 0.3,
        }),

        // Display data points
        showDataPoints
          ? Plot.dot(
              dataset,
              Plot.dodgeY("middle", { x: "value", fill: "lightblue" })
            )
          : null,

        // SampleMean
        showSampleMean
          ? Plot.dot(
              dataset,
              Plot.groupY(
                { x: "mean" },
                {
                  x: "value",
                  y: 0,
                  fy: filtered ? selected_filter : null,
                  fill: "blue",
                }
              )
            )
          : null,

        // CI around the SampleMean
        showSampleMean
          ? Plot.link(
              dataset,
              Plot.groupY(
                { x1: lci_reducer, x2: hci_reducer },
                {
                  x1: "value",
                  x2: "value",
                  y: 0,
                  fy: filtered ? selected_filter : null,
                  marker: "tick",
                  stroke: "blue",
                  strokeWidth: 1.5,
                }
              )
            )
          : null,

        // Tooltip on SampleMeans
        showSampleMean
          ? Plot.tip(
              dataset,
              Plot.groupY(
                {
                  x: "mean",
                  title: (group) =>
                    d3.format(".2f")(d3.mean(group.map((d) => d.value))),
                },
                {
                  x: "value",
                  y: 0,
                  fy: filtered ? selected_filter : null,
                  dy: -4,
                  fontSize: 10,
                  fontWeight: "bold",
                  fillOpacity: 0.4,
                  textPadding: 4,
                }
              )
            )
          : null,
        //Display UserValue
        userValue
          ? Plot.dot(
              { length: 1 },
              {
                x: userValue,
                stroke: "green",
                symbol: "times",
                r: 10,
                strokeWidth: 3,
                tip: { anchor: getLabelOriantation(userValue), maxRadius: 6 },
                title: () => `Your Score: ${userValue}`,
              }
            )
          : null,
      ],
    });

    containerRef.current.append(plot);
    return () => plot.remove();
  }, [dataset, selected_filter]);

  return <div ref={containerRef} />;
};
