
import { useSelector } from "react-redux";
import {
  SelectCurrentFilters,
  SelectAllFilters,
  SelectCurrentProject
} from "../../features/projects/projectSlice";
import { selectActiveRole } from "../../features/auth/authSlice";
import { useGetBalanceQuery } from "../../features/report/balanceApiSlice";
import Loader from "../Loader";
import ViolinLayout from "../graph/ViolinLayout";
import FilterBar from "../../layout/FilterBar";
import { useMemo } from "react";

const Inail = () => {
  const selectedProject = useSelector(SelectCurrentProject)
  const selectedFilters = useSelector(SelectCurrentFilters);
  const filterArray = useSelector(SelectAllFilters);
  const activeRole = useSelector(selectActiveRole);

  // Handle filter checking memoization
  const isFiltered = useMemo(() => {
    return activeRole === "user_admin" && selectedFilters.length > 0 && selectedFilters[0] !== "Overall";
  }, [activeRole, selectedFilters]);

  const { data, isLoading, isSuccess, isError, error } = useGetBalanceQuery(selectedProject);

  const selectedFilterKey = selectedFilters[0];
  const filter = filterArray[selectedFilterKey];

  if (isLoading) {
    return <Loader />;
  } else if (isError) {
    return <p>{JSON.stringify(error)}</p>;
  }

  return (
    <>
    <FilterBar selectedFilters={selectedFilters} />

    <div className="container">
        <div className="row">
        {data.scale_data && data.scale_data.map((item, i) => (
            <ViolinLayout
              key={i}
              dataset={item}
              filtered={isFiltered}
              selected_filter={filter}
              userValue={data.scale_user_values[i]}
              benchmarkValue={data.scale_benchmark_values[i]}
              title={data.scale_titles[i]}
              info_button={true}
              scale_info={data.scale_descriptions[i]}
            />
          ))}
        </div>
      </div>
  </>
  );
};

export default Inail;
