import { useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  selectNumberParticipants,
  setParticipants,
  resetParticipants,
  selectAllWizard,
} from "../../features/wizard/wizardSlice";
import { ButtonWithIcon } from "../../components/ButtonWithIcon";
import { PrevNext } from "./components/PrevNext";
import { excelToPartecipanti, readXslx } from "../../utils/Excel";
import { wizard } from "../../config/routes";
import { EmployeeTable } from "../../components/data-table/EmployeeTable";
import { selectParticipants } from "../../features/wizard/wizardSlice";

export const Participants = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const count = useSelector(selectNumberParticipants);
  const fileInput = useRef(null);
  const wizardData = useSelector(selectAllWizard);

  const participants = useSelector(selectParticipants);

  useEffect(() => {
    const selectedModules = (data) => {
      let count = 0;
      data.forEach((module) => (module.selected === true ? count++ : count));
      return count;
    };
    if (selectedModules(wizardData.modules) === 0) {
      navigate(wizard[0].path);
    }
    // eslint-disable-next-line
  }, []);



  let allKeys = participants.map((p) => Object.keys(p)).flat(1);
  const head = Array.from(new Set(allKeys)).map((item) => ({
    label: item,
    type: "text",
  }));


  const COLUMNS = head.map((item) => {
    return {
      Header: item.label,
      accessor: item.label,
      editable: false,
    };
  });

  return (
    <>
      <div className="container ">
        <PrevNext
          prevClicked={() => {
            navigate(wizard[0].path);
          }}
          nextClicked={() => {
            navigate(wizard[2].path);
          }}
          nextDisabled={!count}
          step={"2 of 4"}
        />
        {
          <div className=" row align-items-end justify-content-center ">
            {count === 0 && (
              <>
                <div className="col-lg-4 my-2 ">
                  <h3>Adding participants</h3>
                  <br></br>
                  Please download and compile the template containing the
                  datafields we need in order to invite the participants.
                  <br></br>
                  After uploading the compiled template file an overview will be
                  displayed to confirm a successfull upload.
                </div>

                <div className="col-lg-2  my-2 text-center">
                  <div className="my-2">
                    <ButtonWithIcon
                      onClick={() => {
                        window.location.href =
                          "/modello_inserimento_contatti.xlsx";
                      }}
                      text={"Download template"}
                    />
                  </div>
                  <div className="my-2">
                    <ButtonWithIcon
                      onClick={() => {
                        fileInput.current.click();
                      }}
                      text={"Upload template"}
                      //iconName={buttonIcons.upload}
                    />
                    <input
                      ref={fileInput}
                      type="file"
                      className="visually-hidden"
                      onChange={(ev) => {
                        readXslx(ev.target.files, (data, error) => {
                          if (error) {
                            alert(error);
                            return;
                          }
                          const participants = excelToPartecipanti(data);
                          dispatch(setParticipants(participants));
                          fileInput.current.value = null;
                        });
                      }}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        }
      </div>

      <div className="container-fluid container-wizard" style={{ top: 0 }}>
        {count > 0 && (
          <div className="container-fluid px-4">
            <ButtonWithIcon
              bgColor={"red"}
              onClick={() => {
                dispatch(resetParticipants());
                fileInput.current.value = null;
              }}
              text={"Clear Data"}
            />
          </div>
        )}
        {count > 0 && (
          <EmployeeTable
            columns={COLUMNS}
            data={participants}
            full_width={true}
          />
        )}
      </div>
    </>
  );
};
