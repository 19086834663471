import * as d3 from "d3";

export function splitArray(data, basetitle) {
  // create list of labels
  if (!data) return [[], []];

  let dataArrays = [];
  let labels = data
    .map((value) => value.target)
    .filter((value, index, _arr) => _arr.indexOf(value) === index);

  labels.forEach((label) => {
    dataArrays.push(data.filter((e) => e.target === label));
  });

  labels[0] = labels[0] === "costrutto" ? basetitle : labels[0];

  return [dataArrays, labels];
}

export function splitData(data, basetitle) {
  // create list of labels
  if (!data) return [[], []];
  let dataArrays = [];
  let labels = data
    .map((value) => value.filter_value)
    .filter((value, index, _arr) => _arr.indexOf(value) === index);

  labels.forEach((label) => {
    dataArrays.push(data.filter((e) => e.filter_value === label));
  });

  labels[0] = labels[0] === "costrutto" ? basetitle : labels[0];

  return [dataArrays, labels];
}

export function reformatData(data) {
  const categories = [...new Set(data.map((value) => value.target))];
  const avg = categories.map((label) =>
    unpack_avg(
      data.filter((e) => e.target === label),
      label
    )
  );

  return [avg, categories];
}

// takes an array and a key and returns all the keyvalues as an array
export function unpack(rows, key) {
  return rows.map((row) => row[key]);
}

export function unpack_avg(rows, label) {
  const filteredRows = rows.filter(
    (row) => row.hasOwnProperty("x") || row.hasOwnProperty("y")
  );
  const sum = { x: 0, y: 0 };
  const count = { x: 0, y: 0 };

  filteredRows.forEach((row) => {
    if (row.hasOwnProperty("x")) {
      sum.x += row.x;
      count.x++;
    }
    if (row.hasOwnProperty("y")) {
      sum.y += row.y;
      count.y++;
    }
  });

  const avg = {
    x: count.x > 0 ? (sum.x / count.x).toFixed(2) : 0,
    y: count.y > 0 ? (sum.y / count.y).toFixed(2) : 0,
  };

  return [{ target: label, ...avg, label }];
}

export const exportData = (data) => {
  const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
    JSON.stringify(data)
  )}`;
  const link = document.createElement("a");
  link.href = jsonString;
  link.download = "data.json";

  link.click();
};

export function filterData(data, filter) {
  return data.scale_data.map((item) =>
    item
      .filter((row) => row["value"] !== null && row[filter] !== null)
      .map((row) => ({ target: row[filter], score: row["value"] }))
  );
}

export function reformatScatterData(data, filter, scatterCodes) {
  
  if (!data || data.length === 0) return null;
  let input = [];
  
  let keyX = scatterCodes[0]
  let keyY = scatterCodes[1]
  data.forEach((row) => {
    if (row[keyX] && row[keyY]) {
      input.push({
        label: row[filter],
        target: row[filter],
        x: row[keyX],
        y: row[keyY],
      });
    }
  });

  if (filter === "costrutto") {
    return [[input], []];
  } else {
    return reformatData(input, filter);
  }
}

// ----------------  Observable Plot Helper functions -------------- //


// function to get unique values from an Array

export function getUniqueValues(arr) {
  return [...new Set(arr)];
}


// function to compute frequentist 95% CI and the mean

export function calculate95CI(data) {
const avg = d3.mean(data);
const stdDev = d3.deviation(data);
const n = data.length;
const z = 1.96;  // z-score for 95% confidence level

// Compute the margin of error
const marginOfError = z * (stdDev / Math.sqrt(n));

// Compute the lower and upper bounds of the 95% CI
const lowerBound = avg - marginOfError;
const upperBound = avg + marginOfError;

// Return an array containing the lower bound, mean, and upper bound
return {lci:lowerBound, avg:avg, hci:upperBound};
}


// functions that extract lci or hci from the previous one
// TODO ned a better implementation

export function lci_reducer(data) {

let res_obj = calculate95CI(data)

return res_obj.lci

}

export function hci_reducer(data) {

let res_obj = calculate95CI(data)

return res_obj.hci

}

// KDE FUNCTIONS

// this function returns a kernel function with a given bandwith
export function epanechnikov(bandwidth) {
return x => Math.abs(x /= bandwidth) <= 1 ? 0.75 * (1 - x * x) / bandwidth : 0.05;
}


// this function uses the kernel on a set of values (thresholds) to compute the kde for an array
// the two_sides argument simply halves the values for use in a violin instead of a normal density plot
// data is returned in an array because it's easy to use in observable area marks

export function kde(kernel, thresholds, data, two_sides=true) {
let kde_values = thresholds.map(t => ({x:t, y: d3.mean(data, d => kernel(t - d))}));

let ret_value = two_sides ? 
  kde_values.map(d => ({x: d.x, y1: d.y/2, y2: d.y/-2})  ) :
  kde_values

return ret_value

}



// REGRESSION function
// this is used to compute the regression line in the contest plots
// 3 arrays as inputs (x,y and size of dots as weight) and returns a and b coeff + predicted values

export function findWeightedLineByLeastSquares(values_x, values_y, weights) {
  if (values_x.length !== values_y.length) {
      throw new Error('The parameters values_x and values_y need to have the same size!');
  }

  if (weights && weights.length !== values_x.length) {
      throw new Error('The weights array must have the same size as values_x and values_y!');
  }

  var sum_w = 0;
  var sum_wx = 0;
  var sum_wy = 0;
  var sum_wxx = 0;
  var sum_wxy = 0;
  var count = values_x.length;

  // Handle case with no data points
  if (count === 0) {
      return [ [], [], 0, 0 ];
  }

  // Calculate weighted sums
  for (var v = 0; v < count; v++) {
      var x = values_x[v];
      var y = values_y[v];
      var w = weights ? weights[v] : 1; // Default weight is 1 if weights not provided

      sum_w += w;
      sum_wx += w * x;
      sum_wy += w * y;
      sum_wxx += w * x * x;
      sum_wxy += w * x * y;
  }

  // Calculate slope (m) and intercept (b) using weighted formulas
  var numerator_m = (sum_w * sum_wxy) - (sum_wx * sum_wy);
  var denominator_m = (sum_w * sum_wxx) - (sum_wx * sum_wx);

  if (denominator_m === 0) {
      throw new Error('Denominator in slope calculation is zero. Check your input data.');
  }

  var m = numerator_m / denominator_m;
  var b = (sum_wy - m * sum_wx) / sum_w;

  // Generate the resulting line points
  var result_values = [];

  for (var v = 0; v < count; v++) {
      var x = values_x[v];
      var y = m * x + b;
      result_values.push([x,y]);
  }

  return {pred_values:result_values, m:m, b:b};
}


// generic helpers

// this might be unnecessary, i use it now to deal with objects coming from d3.rollup()
export function flattenNestedMap(nestedMap) {
const result = [];

// Iterate over the outer Map
nestedMap.forEach((innerMap, outerKey) => {
  // Iterate over the inner Map
  innerMap.forEach((innermostObject, innerKey) => {
    // Clone the innermost object and add outer and inner keys
    result.push({
      ...innermostObject,
      outerKey: outerKey  // Add the outer key as a property
    });
  });
});

return result;
}

