import React, { useState } from 'react'
import { useAsyncDebounce } from 'react-table'
export const ColumnFilter = ({ column }) => {
  const { filterValue, setFilter} = column
  const [value, setValue] = useState(filterValue)

  //the debounce funtion creates a delay so not every event wil result in a Filter action
  const handleChange = useAsyncDebounce((value) => {
    setFilter(value || undefined)
  }, 700)

    return (

        <span>
          <input
            type='text'
            value={value || ''}
            onChange={(e) => {
            setValue(e.target.value)
            handleChange(e.target.value)
          }}
          placeholder= 'Search'
          />
        </span>
    )
}
