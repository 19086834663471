import { useSelector } from "react-redux";
import {
  SelectCurrentFilters,
  SelectAllFilters,
  SelectCurrentProject,
} from "../../features/projects/projectSlice";
import { selectActiveRole } from "../../features/auth/authSlice";
import { useGetTrustQuery } from "../../features/report/trustApiSlice";
import Loader from "../Loader";
import ViolinLayout from "../graph/ViolinLayout";
import FilterBar from "../../layout/FilterBar";
import { reformatScatterData } from "../functions/functions";
import ScatterStackedLayout from "../graph/ScatterStackedLayout";
import { useMemo } from "react";

const Trust = () => {
  const selectedProject = useSelector(SelectCurrentProject);
  const selectedFilters = useSelector(SelectCurrentFilters);
  const filterArray = useSelector(SelectAllFilters);
  const activeRole = useSelector(selectActiveRole);

  const { data, isLoading, isSuccess, isError, error } = useGetTrustQuery(selectedProject);

  // Handle filter checking memoization
  const isFiltered = useMemo(() => {
    return activeRole === "user_admin" && selectedFilters.length > 0 && selectedFilters[0] !== "Overall";
  }, [activeRole, selectedFilters]);

  const selectedFilterKey = selectedFilters[0];
  const filter = filterArray[selectedFilterKey];

  const scatterData = useMemo(() => {
    if (isSuccess) {
      return reformatScatterData(data.scatter_data, filter, data.scatter_scale_codes);
    }
    return null;
  }, [isSuccess, data, filter]);

  if (isLoading) {
    return <Loader />;
  } else if (isError) {
    return <p>{JSON.stringify(error)}</p>;
  }

  return (
    <>
      <FilterBar selectedFilters={selectedFilters} />
      <div className="container">
        <div className="row">
          {data.scale_data && data.scale_data.map((item, i) => (
            <ViolinLayout
              key={i}
              dataset={item}
              filtered={isFiltered}
              selected_filter={filter}
              userValue={data.scale_user_values[i]}
              benchmarkValue={data.scale_benchmark_values[i]}
              title={data.scale_titles[i]}
              info_button={true}
              scale_info={data.scale_descriptions[i]}
            />
          ))}
          {scatterData && activeRole === "user_admin" && (
            <ScatterStackedLayout
              dataset={scatterData[0]}
              legenda={scatterData[1]}
              showlegend={scatterData[1].length !== 0}
              labeled={true}
              dashLines={true}
              full_width={false}
              range={[[0, 6.1], [0, 6.1]]}
              zeroline={false}
              titles={data.scatter_titles[0]}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Trust;
