import { useEffect, useState } from "react";
import Plotly from "./customPlotly";
import createPlotlyComponent from "react-plotly.js/factory";
import { unpack } from "../functions/functions";

export const ViolinMultiChart = ({
  dataset,
  xtitle,
  ytitle,
  multiple,
  margins,
  marker,
  showDataPoints,
  markerData,
}) => {
  const [data, setData] = useState([]);
  const Plot = createPlotlyComponent(Plotly);

  const points = showDataPoints === false ? false : "all";

  useEffect(() => {
    let margin_left = 0;
    let trace = {
      type: "violin",
      hoveron: "points",
      spanmode: "hard",
      points: points,
      pointpos: 0,
      box: {
        visible: false,
      },
      boxpoints: false,
      line: {
        color: "rgba(0,157,221,1)",
      },
      fillcolor: "rgba(0,157,221,.44)",
      opacity: 1,
      showlegend: false,
      jitter: 1,
      marker: {
        color: "rgba(0,157,221,1)",
        size: 4,
      },
      meanline: {
        visible: true,
      },
      y0: "Score",
    };

    let trace_marker = {
      type: "scatter",
      hovertemplate: "<b>%{text}</b>" + "<br><b>Value</b>: %{x}<br> <extra></extra>",
      text: [markerData.label],
      marker: {
        color: "rgb(17, 157, 255)",
        size: 16,
      },
      showlegend: false,
    };

    let input = [];
    if (multiple) {
      // create list of labels
      let testlabels = dataset
        .map((value) => value.target)
        .filter((value, index, _arr) => _arr.indexOf(value) === index);
      testlabels.forEach((label) => {
        if (label.length > margin_left) {
          margin_left = label.length;
        }

        input.push({
          ...trace,
          x: unpack(
            dataset.filter((e) => e.target === label),
            "score"
          ),
          y0: label,
        });
      });
    } else {
      input.push({
        ...trace,
        x: unpack(dataset, "score"),
        y0: "Overall",
      });
      if (marker) {
        input.push({
          ...trace_marker,
          x: [markerData.value],
          y0: "Overall",
        });
      }
    }
    setData(input);

    // eslint-disable-next-line
  }, [dataset]);

  return (
    <div className="overflow-hidden">
      <Plot
        style={{ width: "100%", height: "100%" }}
        useResizeHandler={true}
        data={data}
        config={{ editable: false }}
        layout={{
          autosize: true,
          font: {
            size: "14",
          },
          margin: {
            l: margins[0],
            r: margins[1],
            t: margins[2],
            b: margins[3],
          },
          xaxis: {
            title: xtitle,
            zeroline: false,
          },
          yaxis: {
            title: ytitle,
            zeroline: false,
          },
        }}
      />
    </div>
  );
};
