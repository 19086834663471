import { useSelector } from "react-redux";
import { SelectCurrentProject } from "../../features/projects/projectSlice";
import ScatterPlotlyTrendlineHorizontalLayout from "../graph/ScatterPlotlyTrendlineHorizontalLayout";
import { useGetLeadersDiscoveryQuery } from "../../features/report/leadersDiscoveryApiSlice";
import Loader from "../Loader";

const LeadersDiscovery = () => {
  const selectedProject = useSelector(SelectCurrentProject);
  const limmits = [0.63, 0.5, 0.37];

  const { data, isLoading, isSuccess, isError, error } =
    useGetLeadersDiscoveryQuery(selectedProject);

  let dataset = null;
  if (isLoading) {
    return <Loader />;
  } else if (isSuccess) {
    if (data.length > 0) {
      dataset = data;
    }
  } else if (isError) {
    return <p>{JSON.stringify(error)}</p>;
  }

  return (
    <>
      {dataset && (
        <div className="container">
          <div className="row">
            <ScatterPlotlyTrendlineHorizontalLayout
              title={"Ability to generate trust: Performance"}
              dataset={data}
              limmits={limmits}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default LeadersDiscovery;
