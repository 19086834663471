import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../auth/authSlice";
import { useGetProjectsQuery } from "./projectApiSlice";


const ProjectsGuard = () => {

  // get user id
  const uid = useSelector(selectCurrentUser)
  const location = useLocation();
  const { data , isLoading } = useGetProjectsQuery(uid)

  if (isLoading) {
    return <p> loading....</p> 
  }

  return data.data?.length > 0 ? (
    <Outlet />
  ) : (
    <Navigate to="/welcome" state={{ from: location }} replace />
  );
};
export default ProjectsGuard;
